import { initKeycloak } from 'auth/authHandler';
import { availableMarkets } from 'config/availableMarkets';
import Secured from 'containers/Secured';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import { setConfiguration } from 'react-grid-system';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import buildStore from 'store';
import getProjectInfo from 'utils/fetchInfo';
import { isIE } from 'utils/ieUtils';
import { sendDatacatError } from 'utils/log';
import { projectId } from 'utils/urlQueries';
import { getPathInfo, isEmployeesIntro, resolveURL } from 'utils/versionUtils';
import App from './app';
import initI18n from './i18n_config/i18n';
import './styles/global.scss';

// set layout grid framework configuration
setConfiguration({
  containerWidths: [1100, 1100, 1100, 1100, 1100],
  breakpoints: [576, 770, 850, 1200, 1600]
});

const store = buildStore();
const rootElement = document.getElementById('react-app');
const currentUrl = `${window.location.href}${window.location.search}`;

// if browser is IE it should be displayed a warning instead of the legal policies content
// App should not be rendered
// IE is not supported
if (!isIE) {
  const { market, polVersion, locale, lang } = getPathInfo();

  // when the app loads, sometimes the URL is incomplete or invalid
  // in that case we must redirect the user to the appropriate path
  resolveURL(lang, locale, market, polVersion);

  // polyfill for window.scrollTo() behaviour: "smooth"
  smoothscroll.polyfill();

  const isHomepage = isEmployeesIntro(window.location.pathname);

  const startApp = async () => {
    if (polVersion === 'employees') {
      await initKeycloak();
    }

    // get project info only if market and project id are valid
    // otherwise, initI18n and start app without requesting the project data
    const data =
      market && projectId ? await getProjectInfo(projectId, market) : null;

    const { isCorporate } = data || false;
    const invalidProject = !data || typeof data === 'undefined';
    const marketHasPolicies = availableMarkets.some(
      (item) => item?.value?.toUpperCase() === market?.toUpperCase()
    );

    if (invalidProject) {
      sendDatacatError({
        type: 'invalid configuration',
        error: `market ${market} not configured for project ${projectId}`,
        url: currentUrl
      });
    }

    // the legal data for the current market/project id id configured
    // but there is no logal content for the current market
    if (!invalidProject && !marketHasPolicies) {
      sendDatacatError({
        url: currentUrl,
        error: `no legal policies content for the market ${market}`,
        type: 'invalid configuration'
      });
    }

    initI18n(isCorporate, invalidProject, isHomepage).then(() => {
      if (polVersion === 'employees') {
        render(
          // employee's policies are accessible only for users with a carlsberg account
          <Provider store={store}>
            <Secured>
              <App projectInfo={data} />
            </Secured>
          </Provider>,
          rootElement
        );
        return;
      }
      render(
        <Provider store={store}>
          <App projectInfo={data} />
        </Provider>,
        rootElement
      );
    });
  };

  startApp();
}
