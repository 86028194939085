export const availableMarkets = [
  {
    name: 'Belgium',
    value: 'BE'
  },
  {
    name: 'Bulgaria',
    value: 'BG'
  },
  {
    name: 'Norway',
    value: 'NO'
  },
  {
    name: 'Cyprus',
    value: 'CY'
  },
  {
    name: 'Croatia',
    value: 'HR'
  },
  {
    name: 'Denmark',
    value: 'DK'
  },
  {
    name: 'Estonia',
    value: 'EE'
  },
  {
    name: 'Finland',
    value: 'FI'
  },
  {
    name: 'France',
    value: 'FR'
  },
  {
    name: 'Germany',
    value: 'DE'
  },
  {
    name: 'Greece',
    value: 'GR'
  },
  {
    name: 'Hungary',
    value: 'HU'
  },
  {
    name: 'Ireland',
    value: 'IE'
  },
  {
    name: 'Italy',
    value: 'IT'
  },
  {
    name: 'Latvia',
    value: 'LV'
  },
  {
    name: 'Lithuania',
    value: 'LT'
  },
  {
    name: 'Poland',
    value: 'PL'
  },
  {
    name: 'Vietnam',
    value: 'VN'
  },
  {
    name: 'Global',
    value: 'HQ'
  },
  {
    name: 'Sweden',
    value: 'SE'
  },
  {
    name: 'United Kingdom',
    value: 'GB'
  }
];
