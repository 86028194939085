import { availableMarkets } from 'config/availableMarkets';
import { i18nPath } from 'config/i18nNs';
import PageLayout from 'layout/PageLayout';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Contact from 'screens/Contact';
import EmployersScreen from 'screens/Employers';
import Homepage from 'screens/Homepage';
import Main from 'screens/Main';
import NotFound from 'screens/NotFound';
import Policy from 'screens/Policy';
import type { RootState } from 'store/reducers';
import { getI18nItems } from 'utils/format';
import { sendDatacatError } from 'utils/log';
import { getPathInfo } from 'utils/versionUtils';

export interface RouterProps {
  locPath: string;
}

const Router: React.FC<RouterProps> = ({ locPath }) => {
  const dispatch = useDispatch();
  const { market } = getPathInfo();
  const currentUrl = `${window.location.href}${window.location.search}`;
  const marketHasPolicies = availableMarkets.some(
    (item) => item?.value?.toUpperCase() === market?.toUpperCase()
  );

  const validProject = useSelector(
    (state: RootState) => state.data.validProject
  );
  const { t } = useTranslation();

  const policyPages = getI18nItems(t, `${i18nPath.policyPages}`);
  const isPolicyPagesMissing = !policyPages || policyPages.length === 0;

  useEffect(() => {
    if (isPolicyPagesMissing) {
      sendDatacatError({
        url: currentUrl,
        error: 'policy pages missing',
        type: 'Umbraco content is empty'
      });
    }
  }, [isPolicyPagesMissing, currentUrl, market]);

  const shouldDisplayPolicy = validProject && marketHasPolicies;

  return (
    <Switch>
      <Route path="/employees/home/">
        <PageLayout noNav>
          <Homepage excom={false} />
        </PageLayout>
      </Route>
      <Route path="/employees/home-excom/">
        <PageLayout noNav>
          <Homepage excom />
        </PageLayout>
      </Route>
      <Route path="/employees/employer/">
        <PageLayout noNav>
          <EmployersScreen />
        </PageLayout>
      </Route>
      {shouldDisplayPolicy && (
        <Route exact path={`/${locPath}`}>
          <PageLayout>
            <Main />
          </PageLayout>
        </Route>
      )}
      {shouldDisplayPolicy && (
        <Route exact path={`/${locPath}/contact`}>
          <PageLayout>
            <Contact />
          </PageLayout>
        </Route>
      )}
      {shouldDisplayPolicy &&
        policyPages.map((item) => {
          const { alias, hideNavigation, displaySpeakUp } = item;
          return (
            <Route exact key={`route-${alias}`} path={`/${locPath}/${alias}`}>
              <PageLayout noNav={hideNavigation} alias={alias}>
                <Policy id={alias} displaySpeakUp={displaySpeakUp} />
              </PageLayout>
            </Route>
          );
        })}

      <Route path="*">
        <PageLayout noNav listLangs={false}>
          <NotFound missingContent={isPolicyPagesMissing} />
        </PageLayout>
      </Route>
    </Switch>
  );
};

export default Router;
